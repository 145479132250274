body {
  margin: 0;
}

.container {
  display: flex;
  width: 100%;
  text-align: center;
}

.fiery-five {
  background: url('https://cdn-tp1.mozu.com/9046-m1/cms/files/794851b9-d518-42c5-8fc0-102b12bc1a39') no-repeat;
  background-size: cover;
}

.bb-challenge {
  /* background: url('https://cdn-tp1.mozu.com/9046-m1/cms/files/eccbc9f6-0f90-4491-9313-2bd89f64892b') no-repeat; */
  background: url('https://cdn-tp1.mozu.com/9046-m1/cms/files/ca4c8df9-4ce3-4664-8418-a8f3654bf6ec') no-repeat;
  background-size: cover;
}

.fiery-five, .bb-challenge {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  
  justify-content: end;
  position: relative;

  align-items: center;
  width: 50%;
  padding: 32px;
}

.logo-wrapper {
  margin-top: 73px;

  position: absolute;
  top: 0;
}

.logo-wrapper, .tag-wrapper {
  width: 100%;
  height: auto;
}

.tag-wrapper {
  height: 100px;
}

.bb-ff-logo, .bb-logo {
  max-width: 500px;
}

.bb-ff-logo, .bb-logo, .tagline {
  display: block;
  margin: 0 auto;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  width: 100%;
  position: relative;
  justify-content: end;
  height: 1000px;
}

.ff-face {
  max-width: 232px;
}

img {
  border: 0;
}

.ff-btn {
  background-color: #f59120;
}

.challenge-btn {
  cursor: pointer;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  width: 100%;
  border: none;
  color: white;
  border-radius: 8px;
  padding: 16px 0;
  font-size: 48px;
  margin: 0 auto;
  z-index: 0;
}

.ff-tag {
  max-width: 700px;
  max-height: 108px;
  height: auto;
}

/*
.bb-link-cont {
  margin-top: 461px;
}
*/

.bb-face {
  position: absolute;
  bottom: 0;
  max-width: 575px;
}

.bb-btn {
  background-color: #b3020a;
}

.bb-tag {
  max-height: 100px;
  height: auto;
}

.non-gdpr {
  .bb-challenge {
    width: 100%;
  }

  .challenge-btn {
    width: 50%;
  }
}

@media only screen and (max-width: 1480px) {
  .challenge-btn {
    font-size: 42px;
  }
  .ff-face {
    max-width: 215px;
  }
  .ff-tag {
    max-width: 500px;
  }
}

@media only screen and (max-width: 1148px) {
  .bb-ff-logo,
  .bb-logo {
    max-width: 400px;
  }
  .challenge-btn {
    font-size: 32px;
  }
  .ff-tag {
    max-width: 400px;
  }
  .bb-tag {
    max-width: 350px;
  }
}

@media only screen and (max-width: 1024px) {
  .challenge-btn {
    font-size: 26px;
  }
  .bb-face {
    max-width: 500px;
  }
  .bb-tag {
    max-height: 82px;
  }
}

@media only screen and (max-width: 892px) {
  .container {
    flex-direction: column;
    height: 100%;
    width: auto;
  }
  .bb-ff-logo,
  .bb-logo {
    max-width: 350px;
  }
  .link-container {
    width: 100%;
    height: 800px;
  }
  .challenge-btn {
    font-size: 42px;
  }

  .fiery-five, .bb-challenge {
    width: 100%;
  }

  .non-gdpr {
    .challenge-btn {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 584px) {
  /*
  .faces {
    display: none;
  }
  */
  .challenge-btn {
    font-size: 28px;
  }
  .link-container.bb-link-cont {
    margin-top:unset;
  }
}

@media only screen and (max-width: 420px) {
  .challenge-btn {
    font-size: 24px;
    padding: 16px 24px;
  }

  .bb-logo, .bb-ff-logo {
    max-width: 250px;
  }

  .ff-tag, .bb-tag {
    max-width: 250px;
  }
}

@media only screen and (max-width: 362px) {
  .challenge-btn {
    font-size: 18px;
  }
}